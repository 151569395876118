import React from 'react';
import { EditableMentionsInput } from '../../../../../SlateElements';
import DefaultDateInput from '../DefaultDateInput';
import DefaultSelectInput from '../DefaultSelectInput';
import { SwitchWithTitle } from '../../../../../switch';
import { getBooleanFromString } from '../../../../../inputs/utils';

const selectTypes = ['country', 'multiple_choice', 'currently_employed'];

export default function DefaultValue({
  type,
  experienceId,
  onChange,
  mentionSteps,
  multipleChoiceOptions,
  defaultValue,
  defaultValueSwitcherDisabled,
}) {
  if (type === 'date_of_birth') {
    return (
      <DefaultDateInput
        experienceId={experienceId}
        onChange={onChange}
        defaultValue={defaultValue}
        mentionSteps={mentionSteps}
      />
    );
  }

  if (selectTypes.includes(type)) {
    return (
      <DefaultSelectInput
        type={type}
        onChange={onChange}
        defaultValue={defaultValue}
        multipleChoiceOptions={multipleChoiceOptions}
      />
    );
  }

  if (type === 'checkbox') {
    return (
      <SwitchWithTitle
        onChange={(e) => onChange({ defaultValue: e.target.checked.toString() })}
        title="Checked by default"
        data-testid="DataFieldEditForm/DefaultCheckedSwitcher"
        noborder="true"
        checked={getBooleanFromString(defaultValue)}
        disabled={defaultValueSwitcherDisabled}
      />
    );
  }

  return (
    <EditableMentionsInput
      experienceId={experienceId}
      steps={mentionSteps}
      placeholder="Enter value or press “[“"
      initialValue={defaultValue}
      dataTestId="DataFieldEditForm/DefaultValueInput"
      onValueChange={(value) => onChange({ defaultValue: value })}
      noTrailingSpace
    />
  );
}
