import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import {
  DEFAULT_DONATION_DATA_FIELDS,
  DISABLED_POLITICAL_DATA_FIELDS_TYPES,
  DEFAULT_POLITICAL_DATA_FIELDS,
  MERGED_POLITICAL_DATA_FIELDS,
} from '../../../../constants';

import {
  getExperiencesList,
  getBlocksList,
  getDataFieldsByType,
} from '../../../api';

import Checkbox from '../../../checkbox';
import { NewItemButton } from '../../../Button';
import {
  Label,
  FormSection,
  HLine,
  Fieldset,
  Legend,
  FlexRow,
} from '../../../globals';
import CurrencySelector from './CurrencySelector';
import {
  InputsWrapper,
  DonationInputWrapper,
  RightButtonWrapper,
  LeftPadding,
  ErrorMessage,
  TooltipIconWrapper,
  CheckboxTooltipIconWrapper,
} from './style';
import DataFieldsSection from '../DataFieldsSection';
import _isEmpty from 'lodash/isEmpty';
import { Info } from 'react-feather';
import AmountInput from './AmountInput';
import DefaultFrequencySection from './DefaultFrequencySection';
import BlockContext from '../../../../contexts/BlockContext';
import Tooltip from '../../../tooltip';
import { useTranslation } from '../../../../i18n';
import BlockLabelInput from '../BlockLabelInput';
import PaymentOptionsSection from './PaymentOptionsSection';

const buildMentionSteps = (t) => [
  {
    type: 'experiences',
    title: t('mentions.expTitle'),
    referencePrefix: 'experience_',
    loadList: getExperiencesList,
  },
  {
    type: 'blocks',
    title: 'Blocks',
    referencePrefix: 'block_',
    loadList: getBlocksList,
  },
  {
    type: 'merge_fields',
    title: 'Block merge fields',
    referencePrefix: '',
    loadList: (blockId) => getDataFieldsByType(blockId, 'money'),
  },
];

const MAX_AMOUNT_ITEMS_COUNT = 4;

const addStyleToOption = (options, cssStyle, index) => {
  return options
    .filter((option) => option.deleted !== true)
    .map((option, currentIndex) =>
      currentIndex === index ? { ...option, cssStyle } : option,
    );
};
// Transform options array before updating Context
const transformOptions = (options) =>
  options
    .filter((option) => option.deleted !== true)
    .map((option) => ({ amount: option.value, cssStyle: option.cssStyle }));

function PaymentSection({
  block,
  errors,
  serializedErrors,
  updateBlockAttributes,
  isEditPage,
  dataFields,
  setDataFields,
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'PaymentSection',
  });
  const stepsWithMoneyMergeFields = React.useMemo(() => buildMentionSteps(t), [
    t,
  ]);
  const [blockState, setBlockState] = useContext(BlockContext);
  const currentAmounts = () => {
    const initAmounts = [{ value: '' }];

    if (block.paymentOptions.length === 0) {
      return initAmounts;
    }

    const donationsByAsk = block.paymentOptions.map((amount) => {
      return {
        value: amount === null ? '' : amount.amount,
        key: amount === null ? '' : amount.id,
        cssStyle: amount.cssStyle,
        deleted: false,
      };
    });

    return donationsByAsk;
  };

  const [amountItems, setAmountItems] = useState(currentAmounts());
  const [recurringPayment, setRecurringPayment] = useState(
    block.donationType === 'recurring' || block.donationType === 'both',
  );
  const [oneTimePayment, setOneTimePayment] = useState(
    block.donationType === 'one_time' || block.donationType === 'both',
  );
  const [donationType, setDonationType] = useState(block.donationType);
  const [isPoliticalDonation, setIsPoliticalDonation] = useState(
    block.politicalDonation,
  );
  const [customDonation, setCustomDonation] = useState(block.customDonation);

  const activeItems = () => {
    return amountItems.filter((item) => !item.deleted);
  };

  const updateCurrency = (e) => {
    const value = e.target.value;
    updateBlockAttributes({ currency: e.target.value });

    // Update Context
    setBlockState((prevState) => ({
      ...prevState,
      currency: value,
    }));
  };

  const addAmountItem = () => {
    const items = activeItems();
    const lastItem = items[items.length - 1];

    if (MAX_AMOUNT_ITEMS_COUNT > items.length) {
      const newAmountItem = { value: '' };

      if (lastItem && lastItem.cssStyle) {
        delete lastItem.cssStyle.id;

        newAmountItem.cssStyle = lastItem.cssStyle;
      }

      const newFields = [...amountItems, newAmountItem];
      setAmountItems(newFields);

      // Update Context
      setBlockState((prevState) => ({
        ...prevState,
        paymentOptions: transformOptions(newFields),
      }));
    }
  };

  const addNewInput = (e) => {
    e.preventDefault();

    addAmountItem();
  };

  const removeInput = (indexForDelete, e) => {
    e.preventDefault();

    const newFields = amountItems.map((item, index) => {
      if (indexForDelete === index) {
        return { ...item, deleted: true };
      } else {
        return { ...item };
      }
    });

    setAmountItems(newFields);

    // Update Context
    setBlockState((prevState) => ({
      ...prevState,
      paymentOptions: transformOptions(newFields),
    }));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.target.innerText?.includes('[')) {
      addAmountItem();
    }
  };

  const updateDonationStyles = (style, index) => {
    if (!style || _isEmpty(style)) {
      return;
    }
    const { cssStyle } = style;
    if (_isEmpty(cssStyle)) {
      return;
    }

    setAmountItems(addStyleToOption(amountItems, cssStyle, index));
    setBlockState((prevState) => {
      if (!prevState || !prevState.paymentOptions) {
        return prevState;
      }
      return {
        ...prevState,
        paymentOptions: addStyleToOption(
          prevState.paymentOptions,
          cssStyle,
          index,
        ),
      };
    });
  };

  const updateDonationField = (value, indexForUpdate) => {
    const newFields = amountItems.map((item, index) => {
      if (indexForUpdate === index) {
        return { ...item, value };
      } else {
        return { ...item };
      }
    });

    setAmountItems(newFields);

    // Update Context
    setBlockState((prevState) => ({
      ...prevState,
      paymentOptions: transformOptions(newFields),
    }));
  };

  const renderNewItemButton = () => {
    if (MAX_AMOUNT_ITEMS_COUNT > activeItems().length) {
      return (
        <DonationInputWrapper>
          <NewItemButton
            noMargin={true}
            onClick={addNewInput}
            data-testid="PaymentSection/NewItemButton"
          >
            + Add Option
          </NewItemButton>
          <RightButtonWrapper />
        </DonationInputWrapper>
      );
    }
  };

  const updateRecurringPayment = (e) => {
    setRecurringPayment(e.target.checked);

    updateDonationType(oneTimePayment, e.target.checked);
  };

  const updateOneTimePayment = (e) => {
    setOneTimePayment(e.target.checked);

    updateDonationType(e.target.checked, recurringPayment);
  };

  const updateDefaultDonationType = (option) => {
    const defaultDonationType = option.value;

    updateBlockAttributes({ defaultDonationType });

    // Update Context
    setBlockState((prevState) => ({
      ...prevState,
      defaultDonationType,
    }));
  };

  const updateDonationType = (oneTimePayment, recurringPayment) => {
    let donationType = '';

    if (recurringPayment && oneTimePayment) {
      donationType = 'both';
    } else if (recurringPayment) {
      donationType = 'recurring';
    } else if (oneTimePayment) {
      donationType = 'one_time';
    }

    updateDefaultDonationType({ value: donationType });
    setDonationType(donationType);

    // Update Context
    setBlockState((prevState) => ({
      ...prevState,
      donationType,
    }));
  };

  useEffect(() => {
    if (isPoliticalDonation) {
      disableRequiredPoliticalDonationFields();
    }
  }, [isPoliticalDonation]);

  const disableRequiredPoliticalDonationFields = () => {
    const newDataFields = dataFields.map((field) => {
      if (
        !field.id &&
        DISABLED_POLITICAL_DATA_FIELDS_TYPES.includes(field.type)
      ) {
        return {
          ...field,
          required: true,
        };
      } else {
        return { ...field };
      }
    });

    setDataFields(newDataFields);
  };

  const toggleRequiredDataFields = (isPolitical) => {
    if (_isEmpty(block.dataFields)) {
      if (isPolitical) {
        setDataFields(
          DEFAULT_DONATION_DATA_FIELDS.concat(DEFAULT_POLITICAL_DATA_FIELDS),
        );
      } else {
        setDataFields(DEFAULT_DONATION_DATA_FIELDS);
      }
    } else {
      if (isPolitical) {
        setDataFields(block.dataFields.concat(DEFAULT_POLITICAL_DATA_FIELDS));
      } else {
        setDataFields(block.dataFields);
      }
    }
  };

  const updatePoliticalDonation = (e) => {
    setIsPoliticalDonation(e.target.checked);

    toggleRequiredDataFields(e.target.checked);
  };

  return (
    <>
      <FormSection data-testid="BlockForm/PaymentSection">
        <BlockLabelInput blockType="donation" />
        <LeftPadding inline={false}>
          <CurrencySelector
            currency={block.currency}
            onChange={updateCurrency}
            disabled={isEditPage}
          />
          <FlexRow>
            <Label>Amount(s)</Label>
            <Tooltip
              disableFocusListener
              disableTouchListener
              title="Include up to 4 predefined amounts. The first amount will be selected by default. If you list only one amount, no amount choices will be displayed to the responder."
              placement="bottom"
              width={280}
            >
              <TooltipIconWrapper>
                <Info size={15} />
              </TooltipIconWrapper>
            </Tooltip>
          </FlexRow>
          <InputsWrapper>
            {amountItems.map((field, index) => {
              return (
                <AmountInput
                  key={index}
                  index={index}
                  payment_option_id={field.key}
                  destroy={field.deleted}
                  value={field.value}
                  currency={block.currency}
                  cssStyle={field.cssStyle}
                  error={errors[`payment_options[${index}].amount`]}
                  onChange={(value) => updateDonationField(value, index)}
                  onSaveStyles={updateDonationStyles}
                  onKeyPress={handleKeyPress}
                  onDelete={(e) => removeInput(index, e)}
                  mentionSteps={stepsWithMoneyMergeFields}
                />
              );
            })}
            {renderNewItemButton()}
          </InputsWrapper>
          <FlexRow>
            <Checkbox
              name="ask[custom_donation]"
              checkedByDefault={customDonation}
              value={customDonation}
              label="Allow custom amount"
              withInput
              onChange={(e) => {
                const checked = e.target.checked;
                setCustomDonation(checked);
                // Update Context
                setBlockState((prevState) => ({
                  ...prevState,
                  customDonation: checked,
                }));
              }}
            />
            <Tooltip
              disableFocusListener
              disableTouchListener
              title="Displays an optional custom amount field to encourage responders to input any amount up to $10,000."
              placement="bottom"
              width={280}
            >
              <CheckboxTooltipIconWrapper>
                <Info size={15} />
              </CheckboxTooltipIconWrapper>
            </Tooltip>
          </FlexRow>

          <HLine />

          <PaymentOptionsSection
            t={t}
            block={block}
            errors={errors}
            achDisabled={
              blockState.connectAccountCapabilities
                ?.us_bank_account_ach_payments !== 'active'
            }
          />

          <HLine />

          <Label>Payment frequency</Label>
          {errors.donation_type && (
            <ErrorMessage>{errors.donation_type[0]}</ErrorMessage>
          )}
          <Checkbox
            value="one_time"
            checkedByDefault={oneTimePayment}
            onChange={updateOneTimePayment}
            label="One-time"
            data-testid="PaymentSection/OneTimeCheckbox"
          />
          <Checkbox
            value="recurring"
            checkedByDefault={recurringPayment}
            onChange={updateRecurringPayment}
            label="Monthly recurring"
            data-testid="PaymentSection/MonthlyRecurringCheckbox"
          />
          <input type="hidden" name="ask[donation_type]" value={donationType} />
          <DefaultFrequencySection
            updateDefaultDonationType={updateDefaultDonationType}
            defaultFrequency={block.defaultDonationType}
            disabled={donationType !== 'both'}
          />

          <HLine />

          <Label>Political donation</Label>
          <Checkbox
            checkedByDefault={isPoliticalDonation}
            name="ask[political_donation]"
            label="This is a political donation"
            onChange={updatePoliticalDonation}
            data-testid="PaymentSection/PoliticalCheckbox"
            withInput
          />
        </LeftPadding>
      </FormSection>
      <Fieldset>
        <Legend>Data to collect</Legend>
        <DataFieldsSection
          setDataFields={setDataFields}
          dataFields={dataFields}
          errors={errors}
          serializedErrors={serializedErrors}
          blockType={block.type}
          disclaimerChecked={block.disclaimerChecked}
          disclaimer={block.disclaimer}
        />
      </Fieldset>
    </>
  );
}

PaymentSection.propTypes = {
  block: PropTypes.shape({
    contentTimeout: PropTypes.number,
    ctaButtonContent: PropTypes.string,
    ctaButtonSettingsChecked: PropTypes.bool,
    currency: PropTypes.string,
    customDonation: PropTypes.bool,
    dataFields: PropTypes.arrayOf(
      PropTypes.shape({
        checked: PropTypes.bool,
        label: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    paymentOptions: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.string,
      }),
    ),
    donationType: PropTypes.string,
    defaultDonationType: PropTypes.string,
    disclaimerChecked: PropTypes.bool,
    disclaimer: PropTypes.object,
    metricsChecked: PropTypes.bool,
    multipleChoiceMultiSelect: PropTypes.bool,
    multipleChoiceOptions: PropTypes.array,
    multipleChoiceOther: PropTypes.bool,
    paypalEmail: PropTypes.string,
    paypalEnabled: PropTypes.bool,
    politicalDonation: PropTypes.bool,
    title: PropTypes.string,
    type: PropTypes.string,
    ugcTypes: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  dataFields: PropTypes.arrayOf(
    PropTypes.shape({
      checked: PropTypes.bool,
      label: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  setDataFields: PropTypes.func,
  updateBlockAttributes: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default PaymentSection;
