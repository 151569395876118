import React from 'react';
import Box from '@mui/material/Box';
import DatePicker from '../../../../../experiences/transitions/ConditionalTransitionForm/components/DatePicker';
import { EditableMentionsInput } from '../../../../../SlateElements';

export default function DefaultDateInput({
  experienceId,
  onChange,
  defaultValue,
  mentionSteps,
}) {
  const [selectedDate, setSelectedDate] = React.useState(defaultValue || '');
  const handleDatePick = (date) => {
    const dateString = date.toLocaleDateString('en-US');
    onChange({ defaultValue: date });
    setSelectedDate(dateString);
  };

  return (
    <Box sx={{ height: 50, display: 'flex' }}>
      <EditableMentionsInput
        experienceId={experienceId}
        steps={mentionSteps}
        placeholder="mm/dd/yyyy"
        initialValue=""
        valueToInsert={selectedDate}
        dataTestId="DataFieldEditForm/DefaultValueInput"
        onValueChange={(value) => onChange({ defaultValue: value })}
        noTrailingSpace
      />
      <DatePicker selectedDate={selectedDate} onChange={handleDatePick} />
    </Box>
  );
}
