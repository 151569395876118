import React, { useState, useCallback, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';

import BlockTypeSelect from './components/BlockTypeSelect';
import BlockDetails from './components/BlockDetails';
import SubmissionButtonSection from './components/SubmissionButtonSection';
import FontSection from './components/FontSection';
import GoalAndMetricSection from './components/GoalAndMetricSection';
import { Fieldset, Legend } from '../globals';
import ExpansionPanel from '../expansionPanel';
import TitleSection from './components/TitleSection';
import BackgroundLayout from './components/BackgroundLayout';
import Tooltip from '../tooltip';
import BackgroundSettings from './components/BackgroundSettings';
import SubmitButton from './components/SubmitButton';
import { subscribeTo } from '../../sharedStore';
import BlockContext from '../../contexts/BlockContext';
import { slateToHtml } from './components/TitleSection/utils';
import SkippableBlockSection from './components/SkippableBlockSection';
import NavigationButtonsSection from './components/NavigationButtonsSection';
import ContentLayoutSection from './components/ContentLayoutSection';
import { useSnackbar } from 'notistack';

export const ExperienceIdContext = React.createContext();

function BlockForm({
  block,
  goals,
  errors,
  serializedErrors,
  askMetric,
  isEditPage,
  successMessage,
  experienceId,
  directUploadUrl,
  ask_uuid,
  experience_uuid,
  workspaceId,
  primaryColor,
}) {
  const [blockAttributes, setBlockAttributes] = useState({
    blockId: block.block_id,
    blockTextColor: block.block_text_color,
    backgroundLayout: block.background_layout,
    referenceMergeMedia: block.reference_merge_media,
    type: block.type,
    ugcTypes: block.ugc_types,
    ctaButtonSettingsChecked: block.cta_button_settings_checked,
    multipleChoiceOther: block.multiple_choice_other,
    multipleChoiceMultiSelect: block.multiple_choice_multi_select,
    multipleChoiceOptions: block.multiple_choice_options,
    currency: block.currency,
    paymentOptions: block.payment_options,
    donationType: block.donation_type,
    defaultDonationType: block.default_donation_type,
    paypalEnabled: block.paypal_enabled,
    paypalEmail: block.paypal_email,
    customDonation: block.custom_donation,
    politicalDonation: block.political_donation,
    dataFields: block.data_fields,
    contentTimeout: block.content_timeout,
    metricsChecked: block.metrics_checked,
    disclaimerChecked: block.disclaimer_checked,
    skippableChecked: block.skippable_checked,
    disclaimer: block.disclaimer,
    backgroundSetting: block.background_setting,
    titleSetting: block.title_setting,
    paymentMethods: block.payment_methods,
    cardEnabled: block.card_enabled,
    achEnabled: block.ach_enabled,
  });
  const [, setBlockState] = useContext(BlockContext);
  const { enqueueSnackbar } = useSnackbar();
  const updateBlockStateContext = (value) => {
    if (value && !_isEmpty(errors)) {
      const { titleSetting, disclaimer } = blockAttributes;

      setBlockState((prevState) => ({
        ...prevState,
        ...blockAttributes,
        titleHtml: slateToHtml(JSON.parse(titleSetting.titleElements)),
        titleSetting: {
          fontSizeMax: titleSetting.fontSizeMax,
          fontSizeMin: titleSetting.fontSizeMin,
          positionBottom: titleSetting.positionBottom,
          positionLeft: titleSetting.positionLeft,
          positionRight: titleSetting.positionRight,
          positionTop: titleSetting.positionTop,
          titleElements: titleSetting.titleElements,
          cssStyle: titleSetting.cssStyle,
        },
        ctaButtons: block.cta_buttons,
        disclaimer: {
          tocLabel: disclaimer ? disclaimer.toc_label : '',
          url: disclaimer ? disclaimer.url : '',
        },
        askLabel: block.ask_label,
        navigationButtons: block.navigation_buttons,
        showNavigationButtons: !_isEmpty(block.navigation_buttons),
      }));
    }
  };
  useEffect(() => {
    // To prevent bug with wrong block settings on preview after invalid form save
    subscribeTo('updateBlockContext', updateBlockStateContext);
  }, []);

  const updateBlockAttributes = useCallback(
    (newAttributes) => {
      setBlockAttributes({ ...blockAttributes, ...newAttributes });
    },
    [blockAttributes],
  );

  useEffect(() => {
    if (errors.base) {
      enqueueSnackbar(errors.base[0], { variant: 'error', autoHideDuration: 3000 });
    }

    if (successMessage) {
      enqueueSnackbar(successMessage, { variant: 'success' });
    }
  }, []);

  return (
    <>
      <ExperienceIdContext.Provider value={experienceId}>
        <form
          className="ask-edit__form"
          action={`/${workspaceId}/experiences/${experience_uuid}/asks/${ask_uuid}`}
          method="post"
          noValidate
          acceptCharset="UTF-8"
        >
          <input type="hidden" name="_method" value="patch" />
          <TitleSection
            defaultTitleElements={blockAttributes.titleSetting.titleElements}
            defaultFont={block.font_family}
          />
          <Fieldset>
            <Legend>Type</Legend>
            <Tooltip
              disableFocusListener
              disableTouchListener
              disableHoverListener={!isEditPage}
              title="Block type can't be changed once you’ve started receiving responses."
              placement="top"
              width={245}
            >
              <div>
                <BlockTypeSelect
                  blockType={blockAttributes.type}
                  updateBlockAttributes={updateBlockAttributes}
                  disabled={isEditPage}
                />
              </div>
            </Tooltip>
          </Fieldset>
          <BlockDetails
            block={blockAttributes}
            updateBlockAttributes={updateBlockAttributes}
            errors={errors}
            serializedErrors={JSON.parse(serializedErrors).errors}
            isEditPage={isEditPage}
          />
          <ExpansionPanel
            name="customizeBlockStyle"
            title="Customize block style"
            data-testid="CustomizeBlockStyle/ExpansionPanel"
            onChange={() => {
              setBlockState((prevState) => ({
                ...prevState,
                ctaButtons: block.cta_buttons,
              }));
            }}
          >
            <div aria-label="block style section">
              <ContentLayoutSection
                titleSetting={blockAttributes.titleSetting}
                errors={errors}
              />
              <Fieldset>
                <FontSection currentFont={block.font_family} />
              </Fieldset>
              <SubmissionButtonSection
                blockType={blockAttributes.type}
                isChecked={blockAttributes.ctaButtonSettingsChecked}
                primaryColor={primaryColor}
                errors={errors}
              />
            </div>
          </ExpansionPanel>
          <ExpansionPanel
            name="backgroundSettings"
            title="Background Settings"
            data-testid="BackgroundSettings/ExpansionPanel"
          >
            <BackgroundSettings
              backgroundSetting={blockAttributes.backgroundSetting}
              directUploadUrl={directUploadUrl}
              errors={errors}
            />
          </ExpansionPanel>
          <ExpansionPanel
            name="mediaSettings"
            title="Media Settings"
            data-testid="MediaSettings/ExpansionPanel"
          >
            <BackgroundLayout
              layoutValue={blockAttributes.backgroundLayout}
              referenceMergeMedia={blockAttributes.referenceMergeMedia}
              backgroundSetting={blockAttributes.backgroundSetting}
              errors={errors}
            />
          </ExpansionPanel>
          <NavigationButtonsSection />
          <GoalAndMetricSection
            goals={goals}
            blockType={blockAttributes.type}
            currency={blockAttributes.currency}
            isChecked={blockAttributes.metricsChecked}
            errors={errors}
            askMetric={askMetric}
            updateBlockAttributes={updateBlockAttributes}
          />
          <SkippableBlockSection isChecked={blockAttributes.skippableChecked} />
          <SubmitButton>Save Changes</SubmitButton>
        </form>
      </ExperienceIdContext.Provider>
    </>
  );
}

BlockForm.propTypes = {
  block: PropTypes.object.isRequired,
  goals: PropTypes.arrayOf(PropTypes.string).isRequired,
  errors: PropTypes.object.isRequired,
  askMetric: PropTypes.object.isRequired,
  isEditPage: PropTypes.bool.isRequired,
  successMessage: PropTypes.string,
  experienceId: PropTypes.number,
  directUploadUrl: PropTypes.string.isRequired,
};

export default BlockForm;
